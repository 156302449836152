import Record from 'Types/Record';

export default Record({
  // url: undefined,
  absPath: undefined,
  filename: undefined,
  // args: [],
  function: undefined,
  lineNo: undefined,
  colNo: undefined,
  offset: 0,
  context: undefined
});
