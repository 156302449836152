import React from 'react';
import { Tooltip } from 'react-tippy';
import cn from 'classnames';
import stl from './Marker.css';
import { activeTarget } from 'Player';
export default function Marker(_a) {
    var _b;
    var target = _a.target, active = _a.active;
    var style = {
        top: target.boundingRect.top + "px",
        left: target.boundingRect.left + "px",
        width: target.boundingRect.width + "px",
        height: target.boundingRect.height + "px",
    };
    return (React.createElement("div", { className: cn(stl.marker, (_b = {}, _b[stl.active] = active, _b)), style: style, onClick: function () { return activeTarget(target.index); } },
        React.createElement("div", { className: stl.index }, target.index + 1),
        React.createElement(Tooltip, { open: active, arrow: true, sticky: true, distance: 15, html: (React.createElement("div", null,
                target.count,
                " Clicks")), trigger: "mouseenter" },
            React.createElement("div", { className: "absolute inset-0" }))));
}
