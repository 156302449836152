export default [
  { value: 'performance.dom_content_loaded.average', text: 'performance.dom_content_loaded.average', unit: 'ms' },
  { value: 'performance.first_meaningful_paint.average', text: 'performance.first_meaningful_paint.average', unit: 'ms' },
  { value: 'performance.page_load_time.average', text: 'performance.page_load_time.average', unit: 'ms' },
  { value: 'performance.dom_build_time.average', text: 'performance.dom_build_time.average', unit: 'ms' },
  { value: 'performance.speed_index.average', text: 'performance.speed_index.average', unit: 'ms' },
  { value: 'performance.page_response_time.average', text: 'performance.page_response_time.average', unit: 'ms' },
  { value: 'performance.ttfb.average', text: 'performance.ttfb.average', unit: 'ms' },
  { value: 'performance.time_to_render.average', text: 'performance.time_to_render.average', unit: 'ms' },
  { value: 'performance.image_load_time.average', text: 'performance.image_load_time.average', unit: 'ms' },
  { value: 'performance.request_load_time.average', text: 'performance.request_load_time.average', unit: 'ms' },
  { value: 'resources.load_time.average', text: 'resources.load_time.average', unit: 'ms' },
  { value: 'resources.missing.count', text: 'resources.missing.count', unit: '' },
  { value: 'errors.4xx_5xx.count', text: 'errors.4xx_5xx.count', unit: '' },
  { value: 'errors.4xx.count', text: 'errors.4xx.count', unit: '' },
  { value: 'errors.5xx.count', text: 'errors.5xx.count', unit: '' },
  { value: 'errors.javascript.impacted_sessions.count', text: 'errors.javascript.impacted_sessions.count', unit: '' },
  { value: 'performance.crashes.count', text: 'performance.crashes.count', unit: '' },
  { value: 'errors.javascript.count', text: 'errors.javascript.count', unit: '' },
  { value: 'errors.backend.count', text: 'errors.backend.count', unit: '' },
];
