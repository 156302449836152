import React, { useEffect } from 'react';
import { fetchList } from 'Duck/sessions';
import { connect } from 'react-redux';
import { NoContent, Loader } from 'UI';
import SessionItem from 'Shared/SessionItem';
import withPermissions from 'HOCs/withPermissions';
import { KEYS } from 'Types/filter/customFilter';
import { applyFilter, addAttribute } from 'Duck/filters';
var AUTOREFRESH_INTERVAL = .5 * 60 * 1000;
function LiveSessionList(props) {
    var loading = props.loading, list = props.list, filters = props.filters;
    var timeoutId;
    var hasUserFilter = filters && filters.filters.map(function (i) { return i.key; }).includes(KEYS.USERID);
    useEffect(function () {
        props.fetchList(filters.toJS());
        timeout();
        return function () {
            clearTimeout(timeoutId);
        };
    }, []);
    var onUserClick = function (userId, userAnonymousId) {
        if (userId) {
            props.addAttribute({ label: 'User Id', key: KEYS.USERID, type: KEYS.USERID, operator: 'is', value: userId });
        }
        else {
            props.addAttribute({ label: 'Anonymous ID', key: 'USERANONYMOUSID', type: "USERANONYMOUSID", operator: 'is', value: userAnonymousId });
        }
        props.applyFilter();
    };
    var timeout = function () {
        timeoutId = setTimeout(function () {
            props.fetchList(filters.toJS());
            timeout();
        }, AUTOREFRESH_INTERVAL);
    };
    return (React.createElement("div", null,
        React.createElement(NoContent, { title: "No live sessions.", subtext: React.createElement("span", null,
                "See how to ",
                React.createElement("a", { target: "_blank", className: "link", href: "https://docs.openreplay.com/plugins/assist" }, 'enable Assist'),
                " if you haven't yet done so."), image: React.createElement("img", { src: "/img/live-sessions.png", style: { width: '70%', marginBottom: '30px' } }), show: !loading && list && list.size === 0 },
            React.createElement(Loader, { loading: loading }, list && list.map(function (session) { return (React.createElement(SessionItem, { key: session.sessionId, session: session, live: true, hasUserFilter: hasUserFilter, onUserClick: onUserClick })); })))));
}
export default withPermissions(['ASSIST_LIVE', 'SESSION_REPLAY'])(connect(function (state) { return ({
    list: state.getIn(['sessions', 'liveSessions']),
    loading: state.getIn(['sessions', 'loading']),
    filters: state.getIn(['filters', 'appliedFilter']),
}); }, {
    fetchList: fetchList, applyFilter: applyFilter, addAttribute: addAttribute
})(LiveSessionList));
