import React from 'react';
import stl from './SelectorCard.css';
import cn from 'classnames';
import { activeTarget } from 'Player';
export default function SelectorCard(_a) {
    var _b;
    var _c = _a.index, index = _c === void 0 ? 1 : _c, target = _a.target, showContent = _a.showContent;
    return (React.createElement("div", { className: cn(stl.wrapper, (_b = {}, _b[stl.active] = showContent, _b)), onClick: function () { return activeTarget(index); } },
        React.createElement("div", { className: stl.top },
            React.createElement("div", { className: stl.index }, index + 1),
            React.createElement("div", { className: "truncate" }, target.selector)),
        showContent && (React.createElement("div", { className: stl.counts },
            React.createElement("div", null,
                target.count,
                " Clicks - ",
                target.percent,
                "%"),
            React.createElement("div", { className: "color-gray-medium" }, "TOTAL CLICKS")))));
}
