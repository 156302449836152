var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useCallback } from 'react';
export default function (state) {
    if (state === void 0) { state = ""; }
    var _a = __read(useState(state), 2), value = _a[0], setValue = _a[1];
    var onChange = useCallback(function (_a) {
        var value = _a.target.value;
        return setValue(value);
    }, []);
    return [value, onChange, setValue];
}
