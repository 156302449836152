var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import useInputState from 'App/hooks/useInputState';
// TODO: maybe a better way exists?
var stopPropagation = function (e) {
    if (e.key === 'Backspace' || e.key === 'Delete') {
        e.stopPropagation();
    }
};
export default function InlineInput(_a) {
    var value = _a.value, commit = _a.commit, className = _a.className;
    var _b = __read(useInputState(value), 2), valueState = _b[0], onChange = _b[1];
    //useEffect(() => setValueState(value), [ value ]);
    var inputRef = useRef(null);
    useEffect(function () {
        var _a, _b;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        (_b = inputRef.current) === null || _b === void 0 ? void 0 : _b.setSelectionRange(0, value.length);
    }, []);
    var onKeyPress = function (e) {
        if (e.key === 'Enter') {
            commit(valueState);
            e.target.blur();
        }
    };
    var onBlur = function () {
        commit(valueState);
    };
    return (React.createElement("input", { value: valueState, size: valueState.length, onChange: onChange, onKeyPress: onKeyPress, onKeyDown: stopPropagation, onBlur: onBlur, ref: inputRef, className: cn("font-mono", className) }));
}
