import React from 'react';
import stl from './noPermission.css';
import { Icon } from 'UI';
function NoPermission(props) {
    return (React.createElement("div", { className: stl.wrapper },
        React.createElement(Icon, { name: "shield-lock", size: "50", className: "py-16" }),
        React.createElement("div", { className: stl.title }, "Not allowed"),
        "You don\u2019t have the necessary permissions to access this feature. Please check with your admin."));
}
export default NoPermission;
