import React from 'react';
import stl from './bookmark.css';
import { Icon } from 'UI';
import { toggleFavorite } from 'Duck/sessions';
import { connect } from 'react-redux';
function Bookmark(_a) {
    var toggleFavorite = _a.toggleFavorite, sessionId = _a.sessionId, favorite = _a.favorite;
    return (React.createElement("div", { className: stl.favoriteWrapper, onClick: function () { return toggleFavorite(sessionId); }, "data-favourite": favorite },
        React.createElement(Icon, { name: favorite ? 'star-solid' : 'star', size: "20" })));
}
export default connect(null, { toggleFavorite: toggleFavorite })(Bookmark);
