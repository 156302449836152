import React from 'react';
import { Icon } from 'UI';
import stl from './roleItem.css';
import cn from 'classnames';
function PermisionLabel(_a) {
    var permission = _a.permission;
    return (React.createElement("div", { className: cn(stl.label) }, permission));
}
function RoleItem(_a) {
    var _b, _c;
    var role = _a.role, deleteHandler = _a.deleteHandler, editHandler = _a.editHandler, isAdmin = _a.isAdmin, permissions = _a.permissions;
    return (React.createElement("div", { className: cn(stl.wrapper) },
        React.createElement(Icon, { name: "user-alt", size: "16", marginRight: "10" }),
        React.createElement("div", { className: "flex items-center" },
            React.createElement("div", { className: "mr-4" }, role.name),
            React.createElement("div", { className: "grid grid-flow-col auto-cols-max" }, role.permissions.map(function (permission) { return (React.createElement(PermisionLabel, { permission: permissions[permission], key: permission.id })
            // <span key={permission.id} className={cn(stl.permission)}>{ permissions[permission].name }</span>
            ); }))),
        isAdmin && (React.createElement("div", { className: stl.actions },
            !!deleteHandler &&
                React.createElement("div", { className: cn(stl.button, (_b = {}, _b[stl.disabled] = role.protected, _b)), onClick: function () { return deleteHandler(role); }, id: "trash" },
                    React.createElement(Icon, { name: "trash", size: "16", color: "teal" })),
            !!editHandler &&
                React.createElement("div", { className: cn(stl.button, (_c = {}, _c[stl.disabled] = role.protected, _c)), onClick: function () { return editHandler(role); } },
                    React.createElement(Icon, { name: "edit", size: "16", color: "teal" }))))));
}
export default RoleItem;
