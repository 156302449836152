var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import { Icon } from 'UI';
import cls from './PlayIconLayer.css';
import clsOv from './overlay.css';
export default function PlayIconLayer(_a) {
    var _b;
    var playing = _a.playing, togglePlay = _a.togglePlay;
    var _c = __read(useState(false), 2), showPlayOverlayIcon = _c[0], setShowPlayOverlayIcon = _c[1];
    var togglePlayAnimated = useCallback(function () {
        setShowPlayOverlayIcon(true);
        togglePlay();
        setTimeout(function () { return setShowPlayOverlayIcon(false); }, 800);
    }, []);
    return (React.createElement("div", { className: clsOv.overlay, onClick: togglePlayAnimated },
        React.createElement("div", { className: cn(cls.iconWrapper, (_b = {},
                _b[cls.zoomIcon] = showPlayOverlayIcon,
                _b)) },
            React.createElement(Icon, { name: playing ? "play" : "pause", color: "gray-medium", size: 30 }))));
}
