import React, { useEffect, useRef } from 'react';
function VideoContainer(_a) {
    var stream = _a.stream, _b = _a.muted, muted = _b === void 0 ? false : _b, _c = _a.width, width = _c === void 0 ? 280 : _c;
    var ref = useRef(null);
    useEffect(function () {
        if (ref.current) {
            ref.current.srcObject = stream;
        }
    }, [ref.current, stream]);
    return (React.createElement("div", null,
        React.createElement("video", { autoPlay: true, ref: ref, muted: muted, style: { width: width } })));
}
export default VideoContainer;
