var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import ListWalker from './ListWalker';
var PerformanceTrackManager = /** @class */ (function (_super) {
    __extends(PerformanceTrackManager, _super);
    function PerformanceTrackManager() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.chart = [];
        _this.isHidden = false;
        _this.timeCorrection = 0;
        _this.heapAvaliable = false;
        _this.fpsAvaliable = false;
        _this.cpuAvaliable = false;
        _this.prevTime = null;
        _this.prevNodesCount = 0;
        return _this;
    }
    PerformanceTrackManager.prototype.add = function (msg) {
        var fps;
        var cpu;
        if (!this.isHidden && this.prevTime != null) {
            var timePassed = msg.time - this.prevTime + this.timeCorrection;
            if (timePassed > 0 && msg.frames >= 0) {
                if (msg.frames > 0) {
                    this.fpsAvaliable = true;
                }
                fps = msg.frames * 1e3 / timePassed; // Multiply by 1e3 as time in ms;
                fps = Math.min(fps, 60); // What if 120?  TODO: alert if more than 60
                if (this.chart.length === 1) {
                    this.chart[0].fps = fps;
                }
            }
            if (timePassed > 0 && msg.ticks >= 0) {
                this.cpuAvaliable = true;
                var tickRate = msg.ticks * 30 / timePassed;
                if (tickRate > 1) {
                    tickRate = 1;
                }
                cpu = Math.round(100 - tickRate * 100);
                if (this.chart.length === 1) {
                    this.chart[0].cpu = cpu;
                }
            }
        }
        this.prevTime = msg.time;
        this.timeCorrection = 0;
        this.heapAvaliable = this.heapAvaliable || msg.usedJSHeapSize > 0;
        this.chart.push({
            usedHeap: msg.usedJSHeapSize,
            totalHeap: msg.totalJSHeapSize,
            fps: fps,
            cpu: cpu,
            time: msg.time,
            nodesCount: this.prevNodesCount,
        });
        _super.prototype.add.call(this, msg);
    };
    PerformanceTrackManager.prototype.setCurrentNodesCount = function (count) {
        this.prevNodesCount = count;
        if (this.chart.length > 0) {
            this.chart[this.chart.length - 1].nodesCount = count;
        }
    };
    PerformanceTrackManager.prototype.handleVisibility = function (msg) {
        if (!this.isHidden && msg.hidden && this.prevTime != null) {
            this.timeCorrection = msg.time - this.prevTime;
        }
        if (this.isHidden && !msg.hidden) {
            this.prevTime = msg.time;
        }
        this.isHidden = msg.hidden;
    };
    Object.defineProperty(PerformanceTrackManager.prototype, "chartData", {
        get: function () {
            return this.chart;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PerformanceTrackManager.prototype, "avaliability", {
        get: function () {
            return {
                cpu: this.cpuAvaliable,
                fps: this.fpsAvaliable,
                heap: this.heapAvaliable,
                nodes: true,
            };
        },
        enumerable: false,
        configurable: true
    });
    return PerformanceTrackManager;
}(ListWalker));
export default PerformanceTrackManager;
