var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import logger from 'App/logger';
import RawMessageReader from './RawMessageReader';
// TODO: composition instead of inheritance
// needSkipMessage() and next() methods here use buf and p protected properties, 
// which should be probably somehow incapsulated
var MFileReader = /** @class */ (function (_super) {
    __extends(MFileReader, _super);
    function MFileReader(data, startTime) {
        var _this = _super.call(this, data) || this;
        _this.startTime = startTime;
        _this.pLastMessageID = 0;
        _this.currentTime = 0;
        _this.error = false;
        return _this;
    }
    MFileReader.prototype.needSkipMessage = function () {
        if (this.p === 0)
            return false;
        for (var i = 7; i >= 0; i--) {
            if (this.buf[this.p + i] !== this.buf[this.pLastMessageID + i]) {
                return this.buf[this.p + i] - this.buf[this.pLastMessageID + i] < 0;
            }
        }
        return true;
    };
    MFileReader.prototype.readRawMessage = function () {
        this.skip(8);
        try {
            return _super.prototype.readMessage.call(this);
        }
        catch (e) {
            this.error = true;
            logger.error("Read message error:", e);
            return null;
        }
    };
    MFileReader.prototype.hasNext = function () {
        return !this.error && this.hasNextByte();
    };
    MFileReader.prototype.next = function () {
        if (!this.hasNext()) {
            return null;
        }
        while (this.needSkipMessage()) {
            this.readRawMessage();
        }
        this.pLastMessageID = this.p;
        var rMsg = this.readRawMessage();
        if (!rMsg) {
            return null;
        }
        if (rMsg.tp === "timestamp") {
            this.currentTime = rMsg.timestamp - this.startTime;
        }
        else {
            var msg = Object.assign(rMsg, {
                time: this.currentTime,
                _index: this.pLastMessageID,
            });
            return [msg, this.pLastMessageID];
        }
        return null;
    };
    return MFileReader;
}(RawMessageReader));
export default MFileReader;
