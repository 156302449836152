var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { Loader, IconButton, Popup, NoContent, SlideModal } from 'UI';
import { connect } from 'react-redux';
import stl from './roles.css';
import RoleForm from './components/RoleForm';
import { init, edit, fetchList, remove as deleteRole, resetErrors } from 'Duck/roles';
import RoleItem from './components/RoleItem';
import { confirm } from 'UI/Confirmation';
import { toast } from 'react-toastify';
function Roles(props) {
    var _this = this;
    var loading = props.loading, instance = props.instance, roles = props.roles, init = props.init, edit = props.edit, deleteRole = props.deleteRole, account = props.account, permissionsMap = props.permissionsMap, removeErrors = props.removeErrors;
    var _a = __read(useState(false), 2), showModal = _a[0], setShowmModal = _a[1];
    var isAdmin = account.admin || account.superAdmin;
    useEffect(function () {
        props.fetchList();
    }, []);
    useEffect(function () {
        if (removeErrors && removeErrors.size > 0) {
            removeErrors.forEach(function (e) {
                toast.error(e);
            });
        }
        return function () {
            props.resetErrors();
        };
    }, [removeErrors]);
    var closeModal = function (showToastMessage) {
        if (showToastMessage) {
            toast.success(showToastMessage);
            props.fetchList();
        }
        setShowmModal(false);
        setTimeout(function () {
            init();
        }, 100);
    };
    var editHandler = function (role) {
        init(role);
        setShowmModal(true);
    };
    var deleteHandler = function (role) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, confirm({
                        header: 'Roles',
                        confirmation: "Are you sure you want to remove this role?"
                    })];
                case 1:
                    if (_a.sent()) {
                        deleteRole(role.roleId);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Loader, { loading: loading },
            React.createElement(SlideModal, { title: instance.exists() ? "Edit Role" : "Add Role", size: "small", isDisplayed: showModal, content: showModal && React.createElement(RoleForm, { closeModal: closeModal }), onClose: closeModal }),
            React.createElement("div", { className: stl.wrapper },
                React.createElement("div", { className: cn(stl.tabHeader, 'flex items-center') },
                    React.createElement("div", { className: "flex items-center mr-auto" },
                        React.createElement("h3", { className: cn(stl.tabTitle, "text-2xl") }, "Manage Roles and Permissions"),
                        React.createElement(Popup, { trigger: React.createElement("div", null,
                                React.createElement(IconButton, { id: "add-button", circle: true, icon: "plus", outline: true, disabled: !isAdmin, onClick: function () { return setShowmModal(true); } })), content: "You don\u2019t have the permissions to perform this action.", disabled: isAdmin, size: "tiny", inverted: true, position: "top left" }))),
                React.createElement(NoContent, { title: "No roles are available.", size: "small", show: false, icon: true },
                    React.createElement("div", { className: '' }, roles.map(function (role) { return (React.createElement(RoleItem, { role: role, isAdmin: isAdmin, permissions: permissionsMap, editHandler: editHandler, deleteHandler: deleteHandler })); })))))));
}
export default connect(function (state) {
    var permissions = state.getIn(['roles', 'permissions']);
    var permissionsMap = {};
    permissions.forEach(function (p) {
        permissionsMap[p.value] = p.name;
    });
    return {
        instance: state.getIn(['roles', 'instance']) || null,
        permissionsMap: permissionsMap,
        roles: state.getIn(['roles', 'list']),
        removeErrors: state.getIn(['roles', 'removeRequest', 'errors']),
        loading: state.getIn(['roles', 'fetchRequest', 'loading']),
        account: state.getIn(['user', 'account'])
    };
}, { init: init, edit: edit, fetchList: fetchList, deleteRole: deleteRole, resetErrors: resetErrors })(Roles);
