// Auto-generated, do not edit
export var TP_MAP = {
    80: "batch_meta",
    0: "timestamp",
    2: "session_disconnect",
    4: "set_page_location",
    5: "set_viewport_size",
    6: "set_viewport_scroll",
    7: "create_document",
    8: "create_element_node",
    9: "create_text_node",
    10: "move_node",
    11: "remove_node",
    12: "set_node_attribute",
    13: "remove_node_attribute",
    14: "set_node_data",
    15: "set_css_data",
    16: "set_node_scroll",
    17: "set_input_target",
    18: "set_input_value",
    19: "set_input_checked",
    20: "mouse_move",
    22: "console_log",
    23: "page_load_timing",
    24: "page_render_timing",
    25: "js_exception",
    27: "raw_custom_event",
    28: "user_id",
    29: "user_anonymous_id",
    30: "metadata",
    37: "css_insert_rule",
    38: "css_delete_rule",
    39: "fetch",
    40: "profiler",
    41: "o_table",
    42: "state_action",
    44: "redux",
    45: "vuex",
    46: "mob_x",
    47: "ng_rx",
    48: "graph_ql",
    49: "performance_track",
    53: "resource_timing",
    54: "connection_information",
    55: "set_page_visibility",
    59: "long_task",
    60: "set_node_attribute_url_based",
    61: "set_css_data_url_based",
    63: "technical_info",
    64: "custom_issue",
    65: "page_close",
    67: "css_insert_rule_url_based",
    69: "mouse_click",
    70: "create_i_frame_document",
    90: "ios_session_start",
    93: "ios_custom_event",
    96: "ios_screen_changes",
    100: "ios_click_event",
    102: "ios_performance_event",
    103: "ios_log",
    105: "ios_network_call",
};
