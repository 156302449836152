var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
export function resolveURL(baseURL, relURL) {
    if (relURL.startsWith('#') || relURL === "") {
        return relURL;
    }
    return new URL(relURL, baseURL).toString();
}
var match = /bar/.exec("foobar");
var re1 = /url\(("[^"]*"|'[^']*'|[^)]*)\)/g;
var re2 = /@import "(.*?)"/g;
function cssUrlsIndex(css) {
    var e_1, _a, e_2, _b;
    var idxs = [];
    var i1 = css.matchAll(re1);
    try {
        // @ts-ignore
        for (var i1_1 = __values(i1), i1_1_1 = i1_1.next(); !i1_1_1.done; i1_1_1 = i1_1.next()) {
            var m = i1_1_1.value;
            // @ts-ignore
            var s = m.index + m[0].indexOf(m[1]);
            var e = s + m[1].length;
            idxs.push([s, e]);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (i1_1_1 && !i1_1_1.done && (_a = i1_1.return)) _a.call(i1_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    var i2 = css.matchAll(re2);
    try {
        // @ts-ignore
        for (var i2_1 = __values(i2), i2_1_1 = i2_1.next(); !i2_1_1.done; i2_1_1 = i2_1.next()) {
            var m = i2_1_1.value;
            // @ts-ignore
            var s = m.index + m[0].indexOf(m[1]);
            var e = s + m[1].length;
            idxs.push([s, e]);
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (i2_1_1 && !i2_1_1.done && (_b = i2_1.return)) _b.call(i2_1);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return idxs;
}
function unquote(str) {
    str = str.trim();
    if (str.length <= 2) {
        return [str, ""];
    }
    if (str[0] == '"' && str[str.length - 1] == '"') {
        return [str.substring(1, str.length - 1), "\""];
    }
    if (str[0] == '\'' && str[str.length - 1] == '\'') {
        return [str.substring(1, str.length - 1), "'"];
    }
    return [str, ""];
}
function rewriteCSSLinks(css, rewriter) {
    var e_3, _a;
    try {
        for (var _b = __values(cssUrlsIndex(css)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var idx = _c.value;
            var f = idx[0];
            var t = idx[1];
            var _d = __read(unquote(css.substring(f, t)), 2), rawurl = _d[0], q = _d[1];
            css = css.substring(0, f) + q + rewriter(rawurl) + q + css.substring(t);
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_3) throw e_3.error; }
    }
    return css;
}
export function resolveCSS(baseURL, css) {
    return rewriteCSSLinks(css, function (rawurl) { return resolveURL(baseURL, rawurl); });
}
