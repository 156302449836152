var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { Dropdown, Loader } from 'UI';
import DateRange from 'Shared/DateRange';
import { connect } from 'react-redux';
import { fetchInsights } from 'Duck/sessions';
import SelectorsList from './components/SelectorsList/SelectorsList';
import { markTargets, Controls as Player } from 'Player';
var JUMP_OFFSET = 1000;
function PageInsightsPanel(_a) {
    var filters = _a.filters, fetchInsights = _a.fetchInsights, _b = _a.events, events = _b === void 0 ? [] : _b, insights = _a.insights, urlOptions = _a.urlOptions, host = _a.host, _c = _a.loading, loading = _c === void 0 ? true : _c;
    var _d = __read(useState(filters), 2), insightsFilters = _d[0], setInsightsFilters = _d[1];
    var onDateChange = function (e) {
        var startDate = e.startDate, endDate = e.endDate, rangeValue = e.rangeValue;
        setInsightsFilters(__assign(__assign({}, insightsFilters), { startDate: startDate, endDate: endDate, rangeValue: rangeValue }));
    };
    useEffect(function () {
        markTargets(insights.toJS());
        return function () {
            markTargets(null);
        };
    }, [insights]);
    useEffect(function () {
        var url = insightsFilters.url ? insightsFilters.url : host + urlOptions[0].value;
        Player.pause();
        fetchInsights(__assign(__assign({}, insightsFilters), { url: url }));
    }, [insightsFilters]);
    var onPageSelect = function (e, _a) {
        var name = _a.name, value = _a.value;
        var event = events.find(function (item) { return item.url === value; });
        Player.jump(event.time + JUMP_OFFSET);
        setInsightsFilters(__assign(__assign({}, insightsFilters), { url: host + value }));
        markTargets([]);
    };
    return (React.createElement("div", { className: "px-4 bg-gray-lightest" },
        React.createElement("div", { className: "my-3 flex -ml-2" },
            React.createElement(DateRange, { rangeValue: insightsFilters.rangeValue, startDate: insightsFilters.startDate, endDate: insightsFilters.endDate, onDateChange: onDateChange, customHidden: true })),
        React.createElement("div", { className: "mb-4 flex items-center" },
            React.createElement("div", { className: "mr-2 flex-shrink-0" }, "In Page"),
            React.createElement(Dropdown, { search: true, labeled: true, placeholder: "change", selection: true, options: urlOptions, name: "url", defaultValue: urlOptions[0].value, onChange: onPageSelect, id: "change-dropdown", className: "customDropdown", style: { minWidth: '80px', width: '100%' } })),
        React.createElement(Loader, { loading: loading },
            React.createElement(SelectorsList, null))));
}
export default connect(function (state) {
    var events = state.getIn(['sessions', 'visitedEvents']);
    return {
        filters: state.getIn(['sessions', 'insightFilters']),
        host: state.getIn(['sessions', 'host']),
        insights: state.getIn(['sessions', 'insights']),
        events: events,
        urlOptions: events.map(function (_a) {
            var url = _a.url, host = _a.host;
            return ({ text: url, value: url, host: host });
        }),
        loading: state.getIn(['sessions', 'fetchInsightsRequest', 'loading']),
    };
}, { fetchInsights: fetchInsights })(PageInsightsPanel);
