// Auto-generated, do not edit
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import PrimitiveReader from './PrimitiveReader';
var RawMessageReader = /** @class */ (function (_super) {
    __extends(RawMessageReader, _super);
    function RawMessageReader() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RawMessageReader.prototype.readMessage = function () {
        var _this = this;
        var p = this.p;
        var resetPointer = function () {
            _this.p = p;
            return null;
        };
        var tp = this.readUint();
        if (tp === null) {
            return resetPointer();
        }
        switch (tp) {
            case 80: {
                var pageNo = this.readUint();
                if (pageNo === null) {
                    return resetPointer();
                }
                var firstIndex = this.readUint();
                if (firstIndex === null) {
                    return resetPointer();
                }
                var timestamp = this.readInt();
                if (timestamp === null) {
                    return resetPointer();
                }
                return {
                    tp: "batch_meta",
                    pageNo: pageNo,
                    firstIndex: firstIndex,
                    timestamp: timestamp,
                };
            }
            case 0: {
                var timestamp = this.readUint();
                if (timestamp === null) {
                    return resetPointer();
                }
                return {
                    tp: "timestamp",
                    timestamp: timestamp,
                };
            }
            case 2: {
                var timestamp = this.readUint();
                if (timestamp === null) {
                    return resetPointer();
                }
                return {
                    tp: "session_disconnect",
                    timestamp: timestamp,
                };
            }
            case 4: {
                var url = this.readString();
                if (url === null) {
                    return resetPointer();
                }
                var referrer = this.readString();
                if (referrer === null) {
                    return resetPointer();
                }
                var navigationStart = this.readUint();
                if (navigationStart === null) {
                    return resetPointer();
                }
                return {
                    tp: "set_page_location",
                    url: url,
                    referrer: referrer,
                    navigationStart: navigationStart,
                };
            }
            case 5: {
                var width = this.readUint();
                if (width === null) {
                    return resetPointer();
                }
                var height = this.readUint();
                if (height === null) {
                    return resetPointer();
                }
                return {
                    tp: "set_viewport_size",
                    width: width,
                    height: height,
                };
            }
            case 6: {
                var x = this.readInt();
                if (x === null) {
                    return resetPointer();
                }
                var y = this.readInt();
                if (y === null) {
                    return resetPointer();
                }
                return {
                    tp: "set_viewport_scroll",
                    x: x,
                    y: y,
                };
            }
            case 7: {
                return {
                    tp: "create_document",
                };
            }
            case 8: {
                var id = this.readUint();
                if (id === null) {
                    return resetPointer();
                }
                var parentID = this.readUint();
                if (parentID === null) {
                    return resetPointer();
                }
                var index = this.readUint();
                if (index === null) {
                    return resetPointer();
                }
                var tag = this.readString();
                if (tag === null) {
                    return resetPointer();
                }
                var svg = this.readBoolean();
                if (svg === null) {
                    return resetPointer();
                }
                return {
                    tp: "create_element_node",
                    id: id,
                    parentID: parentID,
                    index: index,
                    tag: tag,
                    svg: svg,
                };
            }
            case 9: {
                var id = this.readUint();
                if (id === null) {
                    return resetPointer();
                }
                var parentID = this.readUint();
                if (parentID === null) {
                    return resetPointer();
                }
                var index = this.readUint();
                if (index === null) {
                    return resetPointer();
                }
                return {
                    tp: "create_text_node",
                    id: id,
                    parentID: parentID,
                    index: index,
                };
            }
            case 10: {
                var id = this.readUint();
                if (id === null) {
                    return resetPointer();
                }
                var parentID = this.readUint();
                if (parentID === null) {
                    return resetPointer();
                }
                var index = this.readUint();
                if (index === null) {
                    return resetPointer();
                }
                return {
                    tp: "move_node",
                    id: id,
                    parentID: parentID,
                    index: index,
                };
            }
            case 11: {
                var id = this.readUint();
                if (id === null) {
                    return resetPointer();
                }
                return {
                    tp: "remove_node",
                    id: id,
                };
            }
            case 12: {
                var id = this.readUint();
                if (id === null) {
                    return resetPointer();
                }
                var name_1 = this.readString();
                if (name_1 === null) {
                    return resetPointer();
                }
                var value = this.readString();
                if (value === null) {
                    return resetPointer();
                }
                return {
                    tp: "set_node_attribute",
                    id: id,
                    name: name_1,
                    value: value,
                };
            }
            case 13: {
                var id = this.readUint();
                if (id === null) {
                    return resetPointer();
                }
                var name_2 = this.readString();
                if (name_2 === null) {
                    return resetPointer();
                }
                return {
                    tp: "remove_node_attribute",
                    id: id,
                    name: name_2,
                };
            }
            case 14: {
                var id = this.readUint();
                if (id === null) {
                    return resetPointer();
                }
                var data = this.readString();
                if (data === null) {
                    return resetPointer();
                }
                return {
                    tp: "set_node_data",
                    id: id,
                    data: data,
                };
            }
            case 15: {
                var id = this.readUint();
                if (id === null) {
                    return resetPointer();
                }
                var data = this.readString();
                if (data === null) {
                    return resetPointer();
                }
                return {
                    tp: "set_css_data",
                    id: id,
                    data: data,
                };
            }
            case 16: {
                var id = this.readUint();
                if (id === null) {
                    return resetPointer();
                }
                var x = this.readInt();
                if (x === null) {
                    return resetPointer();
                }
                var y = this.readInt();
                if (y === null) {
                    return resetPointer();
                }
                return {
                    tp: "set_node_scroll",
                    id: id,
                    x: x,
                    y: y,
                };
            }
            case 17: {
                var id = this.readUint();
                if (id === null) {
                    return resetPointer();
                }
                var label = this.readString();
                if (label === null) {
                    return resetPointer();
                }
                return {
                    tp: "set_input_target",
                    id: id,
                    label: label,
                };
            }
            case 18: {
                var id = this.readUint();
                if (id === null) {
                    return resetPointer();
                }
                var value = this.readString();
                if (value === null) {
                    return resetPointer();
                }
                var mask = this.readInt();
                if (mask === null) {
                    return resetPointer();
                }
                return {
                    tp: "set_input_value",
                    id: id,
                    value: value,
                    mask: mask,
                };
            }
            case 19: {
                var id = this.readUint();
                if (id === null) {
                    return resetPointer();
                }
                var checked = this.readBoolean();
                if (checked === null) {
                    return resetPointer();
                }
                return {
                    tp: "set_input_checked",
                    id: id,
                    checked: checked,
                };
            }
            case 20: {
                var x = this.readUint();
                if (x === null) {
                    return resetPointer();
                }
                var y = this.readUint();
                if (y === null) {
                    return resetPointer();
                }
                return {
                    tp: "mouse_move",
                    x: x,
                    y: y,
                };
            }
            case 22: {
                var level = this.readString();
                if (level === null) {
                    return resetPointer();
                }
                var value = this.readString();
                if (value === null) {
                    return resetPointer();
                }
                return {
                    tp: "console_log",
                    level: level,
                    value: value,
                };
            }
            case 23: {
                var requestStart = this.readUint();
                if (requestStart === null) {
                    return resetPointer();
                }
                var responseStart = this.readUint();
                if (responseStart === null) {
                    return resetPointer();
                }
                var responseEnd = this.readUint();
                if (responseEnd === null) {
                    return resetPointer();
                }
                var domContentLoadedEventStart = this.readUint();
                if (domContentLoadedEventStart === null) {
                    return resetPointer();
                }
                var domContentLoadedEventEnd = this.readUint();
                if (domContentLoadedEventEnd === null) {
                    return resetPointer();
                }
                var loadEventStart = this.readUint();
                if (loadEventStart === null) {
                    return resetPointer();
                }
                var loadEventEnd = this.readUint();
                if (loadEventEnd === null) {
                    return resetPointer();
                }
                var firstPaint = this.readUint();
                if (firstPaint === null) {
                    return resetPointer();
                }
                var firstContentfulPaint = this.readUint();
                if (firstContentfulPaint === null) {
                    return resetPointer();
                }
                return {
                    tp: "page_load_timing",
                    requestStart: requestStart,
                    responseStart: responseStart,
                    responseEnd: responseEnd,
                    domContentLoadedEventStart: domContentLoadedEventStart,
                    domContentLoadedEventEnd: domContentLoadedEventEnd,
                    loadEventStart: loadEventStart,
                    loadEventEnd: loadEventEnd,
                    firstPaint: firstPaint,
                    firstContentfulPaint: firstContentfulPaint,
                };
            }
            case 24: {
                var speedIndex = this.readUint();
                if (speedIndex === null) {
                    return resetPointer();
                }
                var visuallyComplete = this.readUint();
                if (visuallyComplete === null) {
                    return resetPointer();
                }
                var timeToInteractive = this.readUint();
                if (timeToInteractive === null) {
                    return resetPointer();
                }
                return {
                    tp: "page_render_timing",
                    speedIndex: speedIndex,
                    visuallyComplete: visuallyComplete,
                    timeToInteractive: timeToInteractive,
                };
            }
            case 25: {
                var name_3 = this.readString();
                if (name_3 === null) {
                    return resetPointer();
                }
                var message = this.readString();
                if (message === null) {
                    return resetPointer();
                }
                var payload = this.readString();
                if (payload === null) {
                    return resetPointer();
                }
                return {
                    tp: "js_exception",
                    name: name_3,
                    message: message,
                    payload: payload,
                };
            }
            case 27: {
                var name_4 = this.readString();
                if (name_4 === null) {
                    return resetPointer();
                }
                var payload = this.readString();
                if (payload === null) {
                    return resetPointer();
                }
                return {
                    tp: "raw_custom_event",
                    name: name_4,
                    payload: payload,
                };
            }
            case 28: {
                var id = this.readString();
                if (id === null) {
                    return resetPointer();
                }
                return {
                    tp: "user_id",
                    id: id,
                };
            }
            case 29: {
                var id = this.readString();
                if (id === null) {
                    return resetPointer();
                }
                return {
                    tp: "user_anonymous_id",
                    id: id,
                };
            }
            case 30: {
                var key = this.readString();
                if (key === null) {
                    return resetPointer();
                }
                var value = this.readString();
                if (value === null) {
                    return resetPointer();
                }
                return {
                    tp: "metadata",
                    key: key,
                    value: value,
                };
            }
            case 37: {
                var id = this.readUint();
                if (id === null) {
                    return resetPointer();
                }
                var rule = this.readString();
                if (rule === null) {
                    return resetPointer();
                }
                var index = this.readUint();
                if (index === null) {
                    return resetPointer();
                }
                return {
                    tp: "css_insert_rule",
                    id: id,
                    rule: rule,
                    index: index,
                };
            }
            case 38: {
                var id = this.readUint();
                if (id === null) {
                    return resetPointer();
                }
                var index = this.readUint();
                if (index === null) {
                    return resetPointer();
                }
                return {
                    tp: "css_delete_rule",
                    id: id,
                    index: index,
                };
            }
            case 39: {
                var method = this.readString();
                if (method === null) {
                    return resetPointer();
                }
                var url = this.readString();
                if (url === null) {
                    return resetPointer();
                }
                var request = this.readString();
                if (request === null) {
                    return resetPointer();
                }
                var response = this.readString();
                if (response === null) {
                    return resetPointer();
                }
                var status_1 = this.readUint();
                if (status_1 === null) {
                    return resetPointer();
                }
                var timestamp = this.readUint();
                if (timestamp === null) {
                    return resetPointer();
                }
                var duration = this.readUint();
                if (duration === null) {
                    return resetPointer();
                }
                return {
                    tp: "fetch",
                    method: method,
                    url: url,
                    request: request,
                    response: response,
                    status: status_1,
                    timestamp: timestamp,
                    duration: duration,
                };
            }
            case 40: {
                var name_5 = this.readString();
                if (name_5 === null) {
                    return resetPointer();
                }
                var duration = this.readUint();
                if (duration === null) {
                    return resetPointer();
                }
                var args = this.readString();
                if (args === null) {
                    return resetPointer();
                }
                var result = this.readString();
                if (result === null) {
                    return resetPointer();
                }
                return {
                    tp: "profiler",
                    name: name_5,
                    duration: duration,
                    args: args,
                    result: result,
                };
            }
            case 41: {
                var key = this.readString();
                if (key === null) {
                    return resetPointer();
                }
                var value = this.readString();
                if (value === null) {
                    return resetPointer();
                }
                return {
                    tp: "o_table",
                    key: key,
                    value: value,
                };
            }
            case 42: {
                var type = this.readString();
                if (type === null) {
                    return resetPointer();
                }
                return {
                    tp: "state_action",
                    type: type,
                };
            }
            case 44: {
                var action = this.readString();
                if (action === null) {
                    return resetPointer();
                }
                var state = this.readString();
                if (state === null) {
                    return resetPointer();
                }
                var duration = this.readUint();
                if (duration === null) {
                    return resetPointer();
                }
                return {
                    tp: "redux",
                    action: action,
                    state: state,
                    duration: duration,
                };
            }
            case 45: {
                var mutation = this.readString();
                if (mutation === null) {
                    return resetPointer();
                }
                var state = this.readString();
                if (state === null) {
                    return resetPointer();
                }
                return {
                    tp: "vuex",
                    mutation: mutation,
                    state: state,
                };
            }
            case 46: {
                var type = this.readString();
                if (type === null) {
                    return resetPointer();
                }
                var payload = this.readString();
                if (payload === null) {
                    return resetPointer();
                }
                return {
                    tp: "mob_x",
                    type: type,
                    payload: payload,
                };
            }
            case 47: {
                var action = this.readString();
                if (action === null) {
                    return resetPointer();
                }
                var state = this.readString();
                if (state === null) {
                    return resetPointer();
                }
                var duration = this.readUint();
                if (duration === null) {
                    return resetPointer();
                }
                return {
                    tp: "ng_rx",
                    action: action,
                    state: state,
                    duration: duration,
                };
            }
            case 48: {
                var operationKind = this.readString();
                if (operationKind === null) {
                    return resetPointer();
                }
                var operationName = this.readString();
                if (operationName === null) {
                    return resetPointer();
                }
                var variables = this.readString();
                if (variables === null) {
                    return resetPointer();
                }
                var response = this.readString();
                if (response === null) {
                    return resetPointer();
                }
                return {
                    tp: "graph_ql",
                    operationKind: operationKind,
                    operationName: operationName,
                    variables: variables,
                    response: response,
                };
            }
            case 49: {
                var frames_1 = this.readInt();
                if (frames_1 === null) {
                    return resetPointer();
                }
                var ticks = this.readInt();
                if (ticks === null) {
                    return resetPointer();
                }
                var totalJSHeapSize = this.readUint();
                if (totalJSHeapSize === null) {
                    return resetPointer();
                }
                var usedJSHeapSize = this.readUint();
                if (usedJSHeapSize === null) {
                    return resetPointer();
                }
                return {
                    tp: "performance_track",
                    frames: frames_1,
                    ticks: ticks,
                    totalJSHeapSize: totalJSHeapSize,
                    usedJSHeapSize: usedJSHeapSize,
                };
            }
            case 53: {
                var timestamp = this.readUint();
                if (timestamp === null) {
                    return resetPointer();
                }
                var duration = this.readUint();
                if (duration === null) {
                    return resetPointer();
                }
                var ttfb = this.readUint();
                if (ttfb === null) {
                    return resetPointer();
                }
                var headerSize = this.readUint();
                if (headerSize === null) {
                    return resetPointer();
                }
                var encodedBodySize = this.readUint();
                if (encodedBodySize === null) {
                    return resetPointer();
                }
                var decodedBodySize = this.readUint();
                if (decodedBodySize === null) {
                    return resetPointer();
                }
                var url = this.readString();
                if (url === null) {
                    return resetPointer();
                }
                var initiator = this.readString();
                if (initiator === null) {
                    return resetPointer();
                }
                return {
                    tp: "resource_timing",
                    timestamp: timestamp,
                    duration: duration,
                    ttfb: ttfb,
                    headerSize: headerSize,
                    encodedBodySize: encodedBodySize,
                    decodedBodySize: decodedBodySize,
                    url: url,
                    initiator: initiator,
                };
            }
            case 54: {
                var downlink = this.readUint();
                if (downlink === null) {
                    return resetPointer();
                }
                var type = this.readString();
                if (type === null) {
                    return resetPointer();
                }
                return {
                    tp: "connection_information",
                    downlink: downlink,
                    type: type,
                };
            }
            case 55: {
                var hidden = this.readBoolean();
                if (hidden === null) {
                    return resetPointer();
                }
                return {
                    tp: "set_page_visibility",
                    hidden: hidden,
                };
            }
            case 59: {
                var timestamp = this.readUint();
                if (timestamp === null) {
                    return resetPointer();
                }
                var duration = this.readUint();
                if (duration === null) {
                    return resetPointer();
                }
                var context = this.readUint();
                if (context === null) {
                    return resetPointer();
                }
                var containerType = this.readUint();
                if (containerType === null) {
                    return resetPointer();
                }
                var containerSrc = this.readString();
                if (containerSrc === null) {
                    return resetPointer();
                }
                var containerId = this.readString();
                if (containerId === null) {
                    return resetPointer();
                }
                var containerName = this.readString();
                if (containerName === null) {
                    return resetPointer();
                }
                return {
                    tp: "long_task",
                    timestamp: timestamp,
                    duration: duration,
                    context: context,
                    containerType: containerType,
                    containerSrc: containerSrc,
                    containerId: containerId,
                    containerName: containerName,
                };
            }
            case 60: {
                var id = this.readUint();
                if (id === null) {
                    return resetPointer();
                }
                var name_6 = this.readString();
                if (name_6 === null) {
                    return resetPointer();
                }
                var value = this.readString();
                if (value === null) {
                    return resetPointer();
                }
                var baseURL = this.readString();
                if (baseURL === null) {
                    return resetPointer();
                }
                return {
                    tp: "set_node_attribute_url_based",
                    id: id,
                    name: name_6,
                    value: value,
                    baseURL: baseURL,
                };
            }
            case 61: {
                var id = this.readUint();
                if (id === null) {
                    return resetPointer();
                }
                var data = this.readString();
                if (data === null) {
                    return resetPointer();
                }
                var baseURL = this.readString();
                if (baseURL === null) {
                    return resetPointer();
                }
                return {
                    tp: "set_css_data_url_based",
                    id: id,
                    data: data,
                    baseURL: baseURL,
                };
            }
            case 63: {
                var type = this.readString();
                if (type === null) {
                    return resetPointer();
                }
                var value = this.readString();
                if (value === null) {
                    return resetPointer();
                }
                return {
                    tp: "technical_info",
                    type: type,
                    value: value,
                };
            }
            case 64: {
                var name_7 = this.readString();
                if (name_7 === null) {
                    return resetPointer();
                }
                var payload = this.readString();
                if (payload === null) {
                    return resetPointer();
                }
                return {
                    tp: "custom_issue",
                    name: name_7,
                    payload: payload,
                };
            }
            case 65: {
                return {
                    tp: "page_close",
                };
            }
            case 67: {
                var id = this.readUint();
                if (id === null) {
                    return resetPointer();
                }
                var rule = this.readString();
                if (rule === null) {
                    return resetPointer();
                }
                var index = this.readUint();
                if (index === null) {
                    return resetPointer();
                }
                var baseURL = this.readString();
                if (baseURL === null) {
                    return resetPointer();
                }
                return {
                    tp: "css_insert_rule_url_based",
                    id: id,
                    rule: rule,
                    index: index,
                    baseURL: baseURL,
                };
            }
            case 69: {
                var id = this.readUint();
                if (id === null) {
                    return resetPointer();
                }
                var hesitationTime = this.readUint();
                if (hesitationTime === null) {
                    return resetPointer();
                }
                var label = this.readString();
                if (label === null) {
                    return resetPointer();
                }
                var selector = this.readString();
                if (selector === null) {
                    return resetPointer();
                }
                return {
                    tp: "mouse_click",
                    id: id,
                    hesitationTime: hesitationTime,
                    label: label,
                    selector: selector,
                };
            }
            case 70: {
                var frameID = this.readUint();
                if (frameID === null) {
                    return resetPointer();
                }
                var id = this.readUint();
                if (id === null) {
                    return resetPointer();
                }
                return {
                    tp: "create_i_frame_document",
                    frameID: frameID,
                    id: id,
                };
            }
            case 90: {
                var timestamp = this.readUint();
                if (timestamp === null) {
                    return resetPointer();
                }
                var projectID = this.readUint();
                if (projectID === null) {
                    return resetPointer();
                }
                var trackerVersion = this.readString();
                if (trackerVersion === null) {
                    return resetPointer();
                }
                var revID = this.readString();
                if (revID === null) {
                    return resetPointer();
                }
                var userUUID = this.readString();
                if (userUUID === null) {
                    return resetPointer();
                }
                var userOS = this.readString();
                if (userOS === null) {
                    return resetPointer();
                }
                var userOSVersion = this.readString();
                if (userOSVersion === null) {
                    return resetPointer();
                }
                var userDevice = this.readString();
                if (userDevice === null) {
                    return resetPointer();
                }
                var userDeviceType = this.readString();
                if (userDeviceType === null) {
                    return resetPointer();
                }
                var userCountry = this.readString();
                if (userCountry === null) {
                    return resetPointer();
                }
                return {
                    tp: "ios_session_start",
                    timestamp: timestamp,
                    projectID: projectID,
                    trackerVersion: trackerVersion,
                    revID: revID,
                    userUUID: userUUID,
                    userOS: userOS,
                    userOSVersion: userOSVersion,
                    userDevice: userDevice,
                    userDeviceType: userDeviceType,
                    userCountry: userCountry,
                };
            }
            case 93: {
                var timestamp = this.readUint();
                if (timestamp === null) {
                    return resetPointer();
                }
                var length_1 = this.readUint();
                if (length_1 === null) {
                    return resetPointer();
                }
                var name_8 = this.readString();
                if (name_8 === null) {
                    return resetPointer();
                }
                var payload = this.readString();
                if (payload === null) {
                    return resetPointer();
                }
                return {
                    tp: "ios_custom_event",
                    timestamp: timestamp,
                    length: length_1,
                    name: name_8,
                    payload: payload,
                };
            }
            case 96: {
                var timestamp = this.readUint();
                if (timestamp === null) {
                    return resetPointer();
                }
                var length_2 = this.readUint();
                if (length_2 === null) {
                    return resetPointer();
                }
                var x = this.readUint();
                if (x === null) {
                    return resetPointer();
                }
                var y = this.readUint();
                if (y === null) {
                    return resetPointer();
                }
                var width = this.readUint();
                if (width === null) {
                    return resetPointer();
                }
                var height = this.readUint();
                if (height === null) {
                    return resetPointer();
                }
                return {
                    tp: "ios_screen_changes",
                    timestamp: timestamp,
                    length: length_2,
                    x: x,
                    y: y,
                    width: width,
                    height: height,
                };
            }
            case 100: {
                var timestamp = this.readUint();
                if (timestamp === null) {
                    return resetPointer();
                }
                var length_3 = this.readUint();
                if (length_3 === null) {
                    return resetPointer();
                }
                var label = this.readString();
                if (label === null) {
                    return resetPointer();
                }
                var x = this.readUint();
                if (x === null) {
                    return resetPointer();
                }
                var y = this.readUint();
                if (y === null) {
                    return resetPointer();
                }
                return {
                    tp: "ios_click_event",
                    timestamp: timestamp,
                    length: length_3,
                    label: label,
                    x: x,
                    y: y,
                };
            }
            case 102: {
                var timestamp = this.readUint();
                if (timestamp === null) {
                    return resetPointer();
                }
                var length_4 = this.readUint();
                if (length_4 === null) {
                    return resetPointer();
                }
                var name_9 = this.readString();
                if (name_9 === null) {
                    return resetPointer();
                }
                var value = this.readUint();
                if (value === null) {
                    return resetPointer();
                }
                return {
                    tp: "ios_performance_event",
                    timestamp: timestamp,
                    length: length_4,
                    name: name_9,
                    value: value,
                };
            }
            case 103: {
                var timestamp = this.readUint();
                if (timestamp === null) {
                    return resetPointer();
                }
                var length_5 = this.readUint();
                if (length_5 === null) {
                    return resetPointer();
                }
                var severity = this.readString();
                if (severity === null) {
                    return resetPointer();
                }
                var content = this.readString();
                if (content === null) {
                    return resetPointer();
                }
                return {
                    tp: "ios_log",
                    timestamp: timestamp,
                    length: length_5,
                    severity: severity,
                    content: content,
                };
            }
            case 105: {
                var timestamp = this.readUint();
                if (timestamp === null) {
                    return resetPointer();
                }
                var length_6 = this.readUint();
                if (length_6 === null) {
                    return resetPointer();
                }
                var duration = this.readUint();
                if (duration === null) {
                    return resetPointer();
                }
                var headers = this.readString();
                if (headers === null) {
                    return resetPointer();
                }
                var body = this.readString();
                if (body === null) {
                    return resetPointer();
                }
                var url = this.readString();
                if (url === null) {
                    return resetPointer();
                }
                var success = this.readBoolean();
                if (success === null) {
                    return resetPointer();
                }
                var method = this.readString();
                if (method === null) {
                    return resetPointer();
                }
                var status_2 = this.readUint();
                if (status_2 === null) {
                    return resetPointer();
                }
                return {
                    tp: "ios_network_call",
                    timestamp: timestamp,
                    length: length_6,
                    duration: duration,
                    headers: headers,
                    body: body,
                    url: url,
                    success: success,
                    method: method,
                    status: status_2,
                };
            }
            default:
                throw new Error("Unrecognizable message type: " + tp);
                return null;
        }
    };
    return RawMessageReader;
}(PrimitiveReader));
export default RawMessageReader;
