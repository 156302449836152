var PrimitiveReader = /** @class */ (function () {
    function PrimitiveReader(buf) {
        if (buf === void 0) { buf = new Uint8Array(0); }
        this.buf = buf;
        this.p = 0;
    }
    PrimitiveReader.prototype.append = function (buf) {
        var newBuf = new Uint8Array(this.buf.length + buf.length);
        newBuf.set(this.buf);
        newBuf.set(buf, this.buf.length);
        this.buf = newBuf;
    };
    PrimitiveReader.prototype.hasNextByte = function () {
        return this.p < this.buf.length;
    };
    PrimitiveReader.prototype.readUint = function () {
        var p = this.p, r = 0, s = 1, b;
        do {
            if (p >= this.buf.length) {
                return null;
            }
            b = this.buf[p++];
            r += (b & 0x7F) * s;
            s *= 128;
        } while (b >= 0x80);
        this.p = p;
        return r;
    };
    PrimitiveReader.prototype.readInt = function () {
        var u = this.readUint();
        if (u === null) {
            return u;
        }
        if (u % 2) {
            u = (u + 1) / -2;
        }
        else {
            u = u / 2;
        }
        return u;
    };
    PrimitiveReader.prototype.readString = function () {
        var l = this.readUint();
        if (l === null || this.p + l > this.buf.length) {
            return null;
        }
        return new TextDecoder().decode(this.buf.subarray(this.p, this.p += l));
    };
    PrimitiveReader.prototype.readBoolean = function () {
        if (this.p >= this.buf.length) {
            return null;
        }
        return !!this.buf[this.p++];
    };
    PrimitiveReader.prototype.skip = function (n) {
        this.p += n;
    };
    return PrimitiveReader;
}());
export default PrimitiveReader;
