import styles from './cursor.css';
var Cursor = /** @class */ (function () {
    function Cursor(overlay) {
        this.position = { x: 0, y: 0 };
        this._cursor = document.createElement('div');
        this._cursor.className = styles.cursor;
        overlay.appendChild(this._cursor);
        //this._click = document.createElement('div');
        //this._click.className = styles.click;
        //overlay.appendChild(this._click);
    }
    Cursor.prototype.toggle = function (flag) {
        if (flag) {
            this._cursor.style.display = 'block';
        }
        else {
            this._cursor.style.display = 'none';
        }
    };
    Cursor.prototype.move = function (_a) {
        var x = _a.x, y = _a.y;
        this.position.x = x;
        this.position.y = y;
        this._cursor.style.left = x + 'px';
        this._cursor.style.top = y + 'px';
    };
    // click() {
    //   this._cursor.style.left = this._x + 'px';
    //   this._cursor.style.top = this._y + 'px';
    //   this._click.style.display = 'block';
    //   setTimeout(() => {
    //     this._click.style.display = "none";
    //   }, 2000);
    // }
    Cursor.prototype.getPosition = function () {
        return { x: this.position.x, y: this.position.y };
    };
    return Cursor;
}());
export default Cursor;
