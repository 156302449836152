var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Screen, { INITIAL_STATE as SUPER_INITIAL_STATE } from './Screen/Screen';
import { update, getState } from '../../store';
export var INITIAL_STATE = __assign(__assign({}, SUPER_INITIAL_STATE), { messagesLoading: false, cssLoading: false, disconnected: false, userPageLoading: false, markedTargets: null, activeTargetIndex: 0 });
var StatedScreen = /** @class */ (function (_super) {
    __extends(StatedScreen, _super);
    function StatedScreen() {
        return _super.call(this) || this;
    }
    StatedScreen.prototype.setMessagesLoading = function (messagesLoading) {
        this.display(!messagesLoading);
        update({ messagesLoading: messagesLoading });
    };
    StatedScreen.prototype.setCSSLoading = function (cssLoading) {
        this.displayFrame(!cssLoading);
        update({ cssLoading: cssLoading });
    };
    StatedScreen.prototype.setDisconnected = function (disconnected) {
        if (!getState().live)
            return; //?
        this.display(!disconnected);
        update({ disconnected: disconnected });
    };
    StatedScreen.prototype.setUserPageLoading = function (userPageLoading) {
        this.display(!userPageLoading);
        update({ userPageLoading: userPageLoading });
    };
    StatedScreen.prototype.setSize = function (_a) {
        var _this = this;
        var height = _a.height, width = _a.width;
        update({ width: width, height: height });
        this.scale();
        var markedTargets = getState().markedTargets;
        if (markedTargets) {
            update({
                markedTargets: markedTargets.map(function (mt) { return (__assign(__assign({}, mt), { boundingRect: _this.calculateRelativeBoundingRect(mt.el) })); }),
            });
        }
    };
    StatedScreen.prototype.calculateRelativeBoundingRect = function (el) {
        if (!this.parentElement)
            return { top: 0, left: 0, width: 0, height: 0 }; //TODO
        var _a = el.getBoundingClientRect(), top = _a.top, left = _a.left, width = _a.width, height = _a.height;
        var s = this.getScale();
        var scrinRect = this.screen.getBoundingClientRect();
        var parentRect = this.parentElement.getBoundingClientRect();
        return {
            top: top * s + scrinRect.top - parentRect.top,
            left: left * s + scrinRect.left - parentRect.left,
            width: width * s,
            height: height * s,
        };
    };
    StatedScreen.prototype.setActiveTarget = function (index) {
        update({ activeTargetIndex: index });
    };
    StatedScreen.prototype.setMarkedTargets = function (selections) {
        var _this = this;
        if (selections) {
            var targets_1 = [];
            var index_1 = 0;
            selections.forEach(function (s) {
                var el = _this.getElementBySelector(s.selector);
                if (!el)
                    return;
                targets_1.push(__assign(__assign({}, s), { el: el, index: index_1++, percent: 0, boundingRect: _this.calculateRelativeBoundingRect(el) }));
            });
            var totalCount_1 = targets_1.reduce(function (a, b) {
                return a + b.count;
            }, 0);
            update({ markedTargets: targets_1.map(function (i) { return (__assign(__assign({}, i), { percent: Math.round((i.count * 100) / totalCount_1) })); }) });
        }
        else {
            update({ markedTargets: null });
        }
    };
    return StatedScreen;
}(Screen));
export default StatedScreen;
