import React from 'react';
import stl from './NoSessionPermission.css';
import { Icon, Button, Link } from 'UI';
import { connect } from 'react-redux';
function NoSessionPermission(_a) {
    var session = _a.session;
    return (React.createElement("div", { className: stl.wrapper },
        React.createElement(Icon, { name: "shield-lock", size: "50", className: "py-16" }),
        React.createElement("div", { className: stl.title }, "Not allowed"),
        session.isLive ?
            React.createElement("span", null, "This session is still live, and you don\u2019t have the necessary permissions to access this feature. Please check with your admin.") :
            React.createElement("span", null, "You don\u2019t have the necessary permissions to access this feature. Please check with your admin."),
        React.createElement(Link, { to: "/" },
            React.createElement(Button, { primary: true, className: "mt-6" }, "GO BACK"))));
}
export default connect(function (state) { return ({
    session: state.getIn(['sessions', 'current']),
}); })(NoSessionPermission);
