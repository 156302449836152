var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Marker from './Marker';
import Cursor from './Cursor';
import Inspector from './Inspector';
import BaseScreen from './BaseScreen';
export { INITIAL_STATE } from './BaseScreen';
var Screen = /** @class */ (function (_super) {
    __extends(Screen, _super);
    function Screen() {
        var _this = _super.call(this) || this;
        _this.substitutor = null;
        _this.inspector = null;
        _this.marker = null;
        _this.cursor = new Cursor(_this.overlay);
        return _this;
    }
    Screen.prototype.getCursorTarget = function () {
        return this.getElementFromInternalPoint(this.cursor.getPosition());
    };
    Screen.prototype.getCursorTargets = function () {
        return this.getElementsFromInternalPoint(this.cursor.getPosition());
    };
    Screen.prototype._scale = function () {
        _super.prototype._scale.call(this);
        if (this.substitutor) {
            this.substitutor._scale();
        }
    };
    Screen.prototype.enableInspector = function (clickCallback) {
        var _a;
        if (!this.parentElement)
            return null;
        if (!this.substitutor) {
            this.substitutor = new Screen();
            this.marker = new Marker(this.substitutor.overlay, this.substitutor);
            this.inspector = new Inspector(this.substitutor, this.marker);
            //this.inspector.addClickListener(clickCallback, true);
            this.substitutor.attach(this.parentElement);
        }
        this.substitutor.display(false);
        var docElement = (_a = this.document) === null || _a === void 0 ? void 0 : _a.documentElement; // this.substitutor.document?.importNode(
        var doc = this.substitutor.document;
        if (doc && docElement) {
            // doc.documentElement.innerHTML = "";
            // // Better way?
            // for (let i = 1; i < docElement.attributes.length; i++) {
            //   const att = docElement.attributes[i];
            //   doc.documentElement.setAttribute(att.name, att.value);
            // }
            // for (let i = 1; i < docElement.childNodes.length; i++) {
            //   doc.documentElement.appendChild(docElement.childNodes[i].cloneNode(true));
            // }
            doc.open();
            doc.write(docElement.outerHTML); // Context will be iframe, so instanceof Element won't work
            doc.close();
            // TODO! : copy stylesheets, check with styles
        }
        this.display(false);
        this.inspector.toggle(true, clickCallback);
        this.substitutor.display(true);
        return doc;
    };
    Screen.prototype.disableInspector = function () {
        if (this.substitutor) {
            var doc = this.substitutor.document;
            if (doc) {
                doc.documentElement.innerHTML = "";
            }
            this.inspector.toggle(false);
            this.substitutor.display(false);
        }
        this.display(true);
    };
    return Screen;
}(BaseScreen));
export default Screen;
