var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Link } from 'UI';
import { session as sessionRoute, withSiteId } from 'App/routes';
import stl from './AutoplayTimer.css';
import clsOv from './overlay.css';
function AutoplayTimer(_a) {
    var nextId = _a.nextId, siteId = _a.siteId, history = _a.history;
    var timer;
    var _b = __read(useState(false), 2), cancelled = _b[0], setCancelled = _b[1];
    var _c = __read(useState(5), 2), counter = _c[0], setCounter = _c[1];
    useEffect(function () {
        if (counter > 0) {
            timer = setTimeout(function () {
                setCounter(counter - 1);
            }, 1000);
        }
        if (counter === 0) {
            history.push(withSiteId(sessionRoute(nextId), siteId));
        }
        return function () { return clearTimeout(timer); };
    }, [counter]);
    var cancel = function () {
        clearTimeout(timer);
        setCancelled(true);
    };
    if (cancelled)
        return '';
    return (React.createElement("div", { className: cn(clsOv.overlay, stl.overlayBg) },
        React.createElement("div", { className: "border p-6 shadow-lg bg-white rounded" },
            React.createElement("div", { className: "py-4" },
                "Next recording will be played in ",
                counter,
                "s"),
            React.createElement("div", { className: "flex items-center" },
                React.createElement(Button, { outline: true, onClick: cancel }, "Cancel"),
                React.createElement("div", { className: "px-3" }),
                React.createElement(Link, { to: sessionRoute(nextId), disabled: !nextId },
                    React.createElement(Button, { primary: true }, "Play Now"))))));
}
export default withRouter(connect(function (state) { return ({
    siteId: state.getIn(['user', 'siteId']),
    nextId: parseInt(state.getIn(['sessions', 'nextId'])),
}); })(AutoplayTimer));
