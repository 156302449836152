var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import ListWalker from './ListWalker';
var HOVER_CN = "-openreplay-hover";
var HOVER_SELECTOR = "." + HOVER_CN;
// Doesn't work with css files (hasOwnProperty)
export function rewriteNodeStyleSheet(doc, node) {
    var ss = Object.values(doc.styleSheets).find(function (s) { return s.ownerNode === node; });
    if (!ss || !ss.hasOwnProperty('rules')) {
        return;
    }
    for (var i = 0; i < ss.rules.length; i++) {
        var r = ss.rules[i];
        if (r instanceof CSSStyleRule) {
            r.selectorText = r.selectorText.replace('/\:hover/g', HOVER_SELECTOR);
        }
    }
}
var StylesManager = /** @class */ (function (_super) {
    __extends(StylesManager, _super);
    function StylesManager(screen) {
        var _this = _super.call(this) || this;
        _this.screen = screen;
        _this.linkLoadingCount = 0;
        _this.linkLoadPromises = [];
        _this.skipCSSLinks = []; // should be common for all pages
        _this.manageRule = function (msg) {
            // if (msg.tp === "css_insert_rule") {
            //   let styleSheet = this.#screen.document.styleSheets[ msg.stylesheetID ];
            //   if (!styleSheet) { 
            //     logger.log("No stylesheet with corresponding ID found: ", msg)
            //     styleSheet = this.#screen.document.styleSheets[0];
            //     if (!styleSheet) {
            //       return;
            //     }
            //   }
            //   try {
            //     styleSheet.insertRule(msg.rule, msg.index);
            //   } catch (e) {
            //     logger.log(e, msg)
            //     //const index = Math.min(msg.index, styleSheet.cssRules.length);
            //     styleSheet.insertRule(msg.rule, styleSheet.cssRules.length);
            //     //styleSheet.ownerNode.innerHTML += msg.rule;
            //   }
            // }
            // if (msg.tp === "css_delete_rule") {
            //   // console.warn('Warning: STYLESHEET_DELETE_RULE msg')
            //   const styleSheet = this.#screen.document.styleSheets[msg.stylesheetID];
            //   if (!styleSheet) { 
            //     logger.log("No stylesheet with corresponding ID found: ", msg)
            //     return; 
            //   }
            //   styleSheet.deleteRule(msg.index);
            // }
        };
        return _this;
    }
    StylesManager.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.linkLoadingCount = 0;
        this.linkLoadPromises = [];
        //cancel all promises? tothinkaboutit
    };
    StylesManager.prototype.setStyleHandlers = function (node, value) {
        var _this = this;
        var timeoutId;
        var promise = new Promise(function (resolve) {
            if (_this.skipCSSLinks.includes(value))
                resolve(null);
            _this.linkLoadingCount++;
            _this.screen.setCSSLoading(true);
            var addSkipAndResolve = function () {
                _this.skipCSSLinks.push(value); // watch out
                resolve(null);
            };
            timeoutId = setTimeout(addSkipAndResolve, 4000);
            node.onload = function () {
                var doc = _this.screen.document;
                doc && rewriteNodeStyleSheet(doc, node);
                resolve(null);
            };
            node.onerror = addSkipAndResolve;
        }).then(function () {
            node.onload = null;
            node.onerror = null;
            clearTimeout(timeoutId);
            _this.linkLoadingCount--;
            if (_this.linkLoadingCount === 0) {
                _this.screen.setCSSLoading(false);
            }
        });
        this.linkLoadPromises.push(promise);
    };
    StylesManager.prototype.moveReady = function (t) {
        var _this = this;
        return Promise.all(this.linkLoadPromises)
            .then(function () { return _this.moveApply(t, _this.manageRule); });
    };
    return StylesManager;
}(ListWalker));
export default StylesManager;
