import React from 'react';
import { Icon, Popup } from 'UI';
import { connectPlayer, toggleEvents, scale } from 'Player';
import cn from 'classnames';
import stl from './EventsToggleButton.css';
function EventsToggleButton(_a) {
    var showEvents = _a.showEvents, toggleEvents = _a.toggleEvents;
    var toggle = function () {
        toggleEvents();
        scale();
    };
    return (React.createElement(Popup, { trigger: React.createElement("button", { className: cn("absolute right-0 z-50", stl.wrapper), onClick: toggle },
            React.createElement(Icon, { name: showEvents ? 'chevron-double-right' : 'chevron-double-left', size: "12" })), content: showEvents ? 'Hide Events' : 'Show Events', size: "tiny", inverted: true, position: "bottom right" }));
}
export default connectPlayer(function (state) { return ({
    showEvents: !state.showEvents
}); }, { toggleEvents: toggleEvents })(EventsToggleButton);
