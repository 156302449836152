import React from 'react';
import { Icon } from 'UI';
import stl from './LiveTag.css';
function LiveTag(_a) {
    var isLive = _a.isLive, onClick = _a.onClick;
    return (React.createElement("button", { onClick: onClick, className: stl.liveTag, "data-is-live": isLive },
        React.createElement(Icon, { name: "circle", size: "8", marginRight: "5", color: "white" }),
        React.createElement("div", null, 'Live')));
}
export default LiveTag;
