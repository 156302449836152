import React from 'react';
import { NoContent } from 'UI';
import stl from './headers.css';
function Headers(props) {
    return (React.createElement("div", null,
        React.createElement(NoContent, { title: "No data available.", size: "small", show: !props.requestHeaders && !props.responseHeaders, icon: "exclamation-circle" },
            props.requestHeaders && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "mb-4 mt-4" },
                    React.createElement("div", { className: "my-2 font-medium" }, "Request Headers"),
                    Object.keys(props.requestHeaders).map(function (h) { return (React.createElement("div", { className: stl.row },
                        React.createElement("span", { className: "mr-2 font-medium" },
                            h,
                            ":"),
                        React.createElement("span", null, props.requestHeaders[h]))); })),
                React.createElement("hr", null))),
            props.responseHeaders && (React.createElement("div", { className: "mt-4" },
                React.createElement("div", { className: "my-2 font-medium" }, "Response Headers"),
                Object.keys(props.responseHeaders).map(function (h) { return (React.createElement("div", { className: stl.row },
                    React.createElement("span", { className: "mr-2 font-medium" },
                        h,
                        ":"),
                    React.createElement("span", null, props.responseHeaders[h]))); }))))));
}
export default Headers;
