function dummyTrack() {
    var canvas = document.createElement("canvas"); //, { width: 0, height: 0})
    canvas.width = canvas.height = 2; // Doesn't work when 1 (?!)
    var ctx = canvas.getContext('2d');
    ctx === null || ctx === void 0 ? void 0 : ctx.fillRect(0, 0, canvas.width, canvas.height);
    requestAnimationFrame(function draw() {
        ctx === null || ctx === void 0 ? void 0 : ctx.fillRect(0, 0, canvas.width, canvas.height);
        requestAnimationFrame(draw);
    });
    // Also works. Probably it should be done once connected.
    //setTimeout(() => { ctx?.fillRect(0,0, canvas.width, canvas.height) }, 4000)
    return canvas.captureStream(60).getTracks()[0];
}
export default function RequestLocalStream() {
    return navigator.mediaDevices.getUserMedia({ audio: true })
        .then(function (aStream) {
        var aTrack = aStream.getAudioTracks()[0];
        if (!aTrack) {
            throw new Error("No audio tracks provided");
        }
        return new _LocalStream(aTrack);
    });
}
var _LocalStream = /** @class */ (function () {
    function _LocalStream(aTrack) {
        this.mediaRequested = false;
        this.onVideoTrackCb = null;
        this.vdTrack = dummyTrack();
        this.stream = new MediaStream([aTrack, this.vdTrack]);
    }
    _LocalStream.prototype.toggleVideo = function () {
        var _this = this;
        if (!this.mediaRequested) {
            return navigator.mediaDevices.getUserMedia({ video: true })
                .then(function (vStream) {
                var vTrack = vStream.getVideoTracks()[0];
                if (!vTrack) {
                    throw new Error("No video track provided");
                }
                _this.stream.addTrack(vTrack);
                _this.stream.removeTrack(_this.vdTrack);
                _this.mediaRequested = true;
                if (_this.onVideoTrackCb) {
                    _this.onVideoTrackCb(vTrack);
                }
                return true;
            })
                .catch(function (e) {
                // TODO: log
                return false;
            });
        }
        var enabled = true;
        this.stream.getVideoTracks().forEach(function (track) {
            track.enabled = enabled = enabled && !track.enabled;
        });
        return Promise.resolve(enabled);
    };
    _LocalStream.prototype.toggleAudio = function () {
        var enabled = true;
        this.stream.getAudioTracks().forEach(function (track) {
            track.enabled = enabled = enabled && !track.enabled;
        });
        return enabled;
    };
    _LocalStream.prototype.onVideoTrack = function (cb) {
        this.onVideoTrackCb = cb;
    };
    _LocalStream.prototype.stop = function () {
        this.stream.getTracks().forEach(function (t) { return t.stop(); });
    };
    return _LocalStream;
}());
