import { TP_MAP } from './raw';
var JSONRawMessageReader = /** @class */ (function () {
    function JSONRawMessageReader(messages) {
        if (messages === void 0) { messages = []; }
        this.messages = messages;
    }
    JSONRawMessageReader.prototype.append = function (messages) {
        this.messages = this.messages.concat(messages);
    };
    JSONRawMessageReader.prototype.readMessage = function () {
        var msg = this.messages.shift();
        if (!msg) {
            return null;
        }
        msg.tp = TP_MAP[msg._id];
        delete msg._id;
        return msg;
    };
    return JSONRawMessageReader;
}());
export default JSONRawMessageReader;
