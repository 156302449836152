var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import useToggle from 'App/hooks/useToggle';
import useForceUpdate from 'App/hooks/useForceUpdate';
import { Icon } from 'UI';
import stl from './inspector.css';
import AttrView from './AttrView';
import TextView from './TextView';
import InlineInput from './InlineInput';
// TODO: to common space
function stopPropagation(cb) {
    return function (e) {
        e.stopPropagation();
        cb();
    };
}
var RESTRICTED_TAGS = ['html', 'body', 'head'];
function TagEditor(_a) {
    var element = _a.element, forceUpdateParent = _a.forceUpdateParent, context = _a.context;
    var _b = __read(useState(false), 2), editing = _b[0], setEditing = _b[1];
    var commitTag = function (newTag) {
        if (newTag !== '' &&
            !RESTRICTED_TAGS.includes(newTag) &&
            element.parentNode &&
            /^[a-zA-Z]+$/.test(newTag) // TODO: sync with spec
        ) {
            var rElem_1 = context.document.createElement(newTag);
            rElem_1.innerHTML = element.innerHTML;
            Array.from(element.attributes).forEach(function (attr) {
                rElem_1.setAttribute(attr.name, attr.value);
            });
            element.parentNode.replaceChild(rElem_1, element);
        }
        setEditing(false);
        forceUpdateParent();
    };
    var tag = element.tagName.toLowerCase();
    return editing && !RESTRICTED_TAGS.includes(tag)
        ? React.createElement(InlineInput, { value: tag, commit: commitTag })
        : React.createElement("span", { className: stl.tag, onDoubleClick: RESTRICTED_TAGS.includes(tag)
                ? undefined
                : function () { return setEditing(true); } }, tag);
}
//const IGNORE_CLASSES = [ "-openreplay-hover" ];
export default function ElementView(_a) {
    var _b, _c;
    var element = _a.element, level = _a.level, _d = _a.context, context = _d === void 0 ? window : _d, openChain = _a.openChain, forceUpdateParent = _a.forceUpdateParent, selectedElement = _a.selectedElement, setSelectedElement = _a.setSelectedElement, onHover = _a.onHover, className = _a.className;
    var _e = __read(useToggle(false), 4), open = _e[0], toggleOpen = _e[1], _ = _e[2], setOpen = _e[3];
    // useEffect(() => { // TODO: common. something like onElementMount
    // 	IGNORE_CLASSES.forEach(cls => element.classList.remove(cls));
    // 	if (element.classList.length === 0) {
    // 		element.removeAttribute("class");	
    // 	}
    // }, [])
    useEffect(function () {
        if (openChain[level] === element) {
            setOpen();
        }
    }, [openChain[level]]);
    var forceUpdate = useForceUpdate();
    var tag = element.tagName.toLowerCase();
    var isSelected = selectedElement === element;
    var selectElement = setSelectedElement
        ? stopPropagation(function () { return setSelectedElement(element); })
        : undefined;
    var onMouseOver = onHover
        ? stopPropagation(function () { return onHover(element); })
        : undefined;
    return (React.createElement("div", { className: cn("font-mono", className, (_b = {},
            _b[stl.bgHighlight] = !open && isSelected,
            _b["hover:bg-gray-light"] = !open && !isSelected,
            _b)), style: { fontSize: '12px' }, onMouseOver: onMouseOver },
        React.createElement("span", { className: cn((_c = {
                    "block": open
                },
                _c[stl.bgHighlight] = open && isSelected,
                _c["hover:bg-gray-light"] = open && !isSelected,
                _c)) },
            React.createElement("span", { role: "button mr-1", onClick: toggleOpen },
                React.createElement(Icon, { inline: true, name: open ? "caret-down-fill" : "caret-right-fill" })),
            React.createElement("span", { onClick: selectElement },
                React.createElement("span", { className: stl.tag }, '<'),
                React.createElement(TagEditor, { element: element, context: context, forceUpdateParent: forceUpdateParent }),
                Array.from(element.attributes).map(function (attr) {
                    return React.createElement(AttrView, { attr: attr, forceUpdateElement: forceUpdate });
                }),
                React.createElement("span", { className: stl.tag }, '>'))),
        open
            ?
                React.createElement(React.Fragment, null, Array.from(element.childNodes).map(function (child) {
                    if (child instanceof context.Element) {
                        return (React.createElement(ElementView, { element: child, context: context, forceUpdateParent: forceUpdate, level: level + 1, openChain: openChain, selectedElement: selectedElement, setSelectedElement: setSelectedElement, onHover: onHover, className: "pl-4" }));
                    }
                    else if (child instanceof context.Text) {
                        if (!child.nodeValue || child.nodeValue.trim() === "") {
                            return null;
                        }
                        return React.createElement(TextView, { text: child });
                    }
                    return null;
                }))
            : '...',
        React.createElement("span", { className: stl.tag },
            '</',
            tag,
            '>')));
}
