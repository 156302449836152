import React from 'react';
import { connectPlayer } from 'Player';
import { getStatusText } from 'Player/MessageDistributor/managers/AssistManager';
import AutoplayTimer from './Overlay/AutoplayTimer';
import PlayIconLayer from './Overlay/PlayIconLayer';
import LiveStatusText from './Overlay/LiveStatusText';
import Loader from './Overlay/Loader';
import ElementsMarker from './Overlay/ElementsMarker';
function Overlay(_a) {
    // useEffect(() =>{
    //   setTimeout(() => markTargets([{ selector: 'div', count:6}]), 5000)
    //   setTimeout(() => markTargets(null), 8000)
    // },[])
    var playing = _a.playing, completed = _a.completed, inspectorMode = _a.inspectorMode, messagesLoading = _a.messagesLoading, loading = _a.loading, live = _a.live, liveStatusText = _a.liveStatusText, autoplay = _a.autoplay, markedTargets = _a.markedTargets, activeTargetIndex = _a.activeTargetIndex, nextId = _a.nextId, togglePlay = _a.togglePlay;
    var showAutoplayTimer = !live && completed && autoplay && nextId;
    var showPlayIconLayer = !live && !markedTargets && !inspectorMode && !loading && !showAutoplayTimer;
    var showLiveStatusText = live && liveStatusText && !loading;
    return (React.createElement(React.Fragment, null,
        showAutoplayTimer && React.createElement(AutoplayTimer, null),
        showLiveStatusText &&
            React.createElement(LiveStatusText, { text: liveStatusText }),
        messagesLoading && React.createElement(Loader, null),
        showPlayIconLayer &&
            React.createElement(PlayIconLayer, { playing: playing, togglePlay: togglePlay }),
        markedTargets && React.createElement(ElementsMarker, { targets: markedTargets, activeIndex: activeTargetIndex })));
}
export default connectPlayer(function (state) { return ({
    playing: state.playing,
    messagesLoading: state.messagesLoading,
    loading: state.messagesLoading || state.cssLoading,
    completed: state.completed,
    autoplay: state.autoplay,
    live: state.live,
    liveStatusText: getStatusText(state.peerConnectionStatus),
    markedTargets: state.markedTargets,
    activeTargetIndex: state.activeTargetIndex,
}); })(Overlay);
