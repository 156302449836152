import React from 'react';
import Highlight from 'react-highlight';
import ToggleContent from 'Shared/ToggleContent';
function AssistNpm(props) {
    return (React.createElement("div", null,
        React.createElement("p", null, "Initialize the tracker then load the @openreplay/tracker-assist plugin."),
        React.createElement("div", { className: "font-bold my-2" }, "Usage"),
        React.createElement(ToggleContent, { label: "Server-Side-Rendered (SSR)?", first: React.createElement(Highlight, { className: "js" }, "import Tracker from '@openreplay/tracker';\nimport trackerAssist from '@openreplay/tracker-assist';\nconst tracker = new Tracker({\n  projectKey: '" + props.projectKey + "',\n});\ntracker.start();\ntracker.use(trackerAssist(options)); // check the list of available options below"), second: React.createElement(Highlight, { className: "js" }, "import OpenReplay from '@openreplay/tracker/cjs';\nimport trackerFetch from '@openreplay/tracker-assist/cjs';\nconst tracker = new OpenReplay({\n  projectKey: '" + props.projectKey + "'\n});\nconst trackerAssist = tracker.use(trackerAssist(options)); // check the list of available options below\n//...\nfunction MyApp() {\n  useEffect(() => { // use componentDidMount in case of React Class Component\n    tracker.start();\n  }, [])\n//...\n}") }),
        React.createElement("div", { className: "font-bold my-2" }, "Options"),
        React.createElement(Highlight, { className: "js" }, "trackerAssist({\n  confirmText: string;\n})")));
}
export default AssistNpm;
