import RawMessageReader from '../MessageDistributor/messages/RawMessageReader';
var Parser = /** @class */ (function () {
    function Parser(byteArray) {
        this.error = false;
        this.reader = new RawMessageReader(byteArray);
    }
    Parser.prototype.parseEach = function (cb) {
        while (this.hasNext()) {
            var msg = this.next();
            if (msg !== null) {
                cb(msg);
            }
        }
    };
    Parser.prototype.hasNext = function () {
        return !this.error && this.reader.hasNextByte();
    };
    Parser.prototype.next = function () {
        try {
            return this.reader.readMessage();
        }
        catch (e) {
            console.warn(e);
            this.error = true;
            return null;
        }
    };
    return Parser;
}());
export default Parser;
