import React from 'react'
import stl from './newBadge.css'

function NewBadge() {
  return (
    <div className={stl.newBadge}>New</div> 
  )
}

export default NewBadge
