var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import RawMessageReader from './RawMessageReader';
import { resolveURL, resolveCSS } from './urlResolve';
var resolveMsg = {
    "set_node_attribute_url_based": function (msg) {
        return (__assign(__assign({}, msg), { value: msg.name === 'src' || msg.name === 'href'
                ? resolveURL(msg.baseURL, msg.value)
                : (msg.name === 'style'
                    ? resolveCSS(msg.baseURL, msg.value)
                    : msg.value), tp: "set_node_attribute" }));
    },
    "set_css_data_url_based": function (msg) {
        return (__assign(__assign({}, msg), { data: resolveCSS(msg.baseURL, msg.data), tp: "set_css_data" }));
    },
    "css_insert_rule_url_based": function (msg) {
        return (__assign(__assign({}, msg), { rule: resolveCSS(msg.baseURL, msg.rule), tp: "css_insert_rule" }));
    }
};
var MStreamReader = /** @class */ (function () {
    function MStreamReader(r) {
        if (r === void 0) { r = new RawMessageReader(); }
        this.r = r;
        // append(buf: Uint8Array) {
        //   this.r.append(buf)
        // }
        this.t0 = 0;
        this.t = 0;
        this.idx = 0;
    }
    MStreamReader.prototype.readNext = function () {
        var msg = this.r.readMessage();
        if (msg === null) {
            return null;
        }
        if (msg.tp === "timestamp" || msg.tp === "batch_meta") {
            this.t0 = this.t0 || msg.timestamp;
            this.t = msg.timestamp - this.t0;
            return this.readNext();
        }
        // why typescript doesn't work here?
        msg = (resolveMsg[msg.tp] || (function (m) { return m; }))(msg);
        return Object.assign(msg, {
            time: this.t,
            _index: this.idx++,
        });
    };
    return MStreamReader;
}());
export default MStreamReader;
