var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import ListWalker from './ListWalker';
var SkipIntervalCls = /** @class */ (function () {
    function SkipIntervalCls(start, end) {
        if (start === void 0) { start = 0; }
        if (end === void 0) { end = 0; }
        this.start = start;
        this.end = end;
    }
    Object.defineProperty(SkipIntervalCls.prototype, "time", {
        get: function () {
            return this.start;
        },
        enumerable: false,
        configurable: true
    });
    SkipIntervalCls.prototype.contains = function (ts) {
        return ts > this.start && ts < this.end;
    };
    return SkipIntervalCls;
}());
var ActivityManager = /** @class */ (function (_super) {
    __extends(ActivityManager, _super);
    function ActivityManager(duration) {
        var _this = _super.call(this) || this;
        _this.endTime = 0;
        _this.minInterval = 0;
        _this.lastActivity = 0;
        _this.endTime = duration;
        _this.minInterval = duration * 0.1;
        return _this;
    }
    ActivityManager.prototype.updateAcctivity = function (time) {
        if (time - this.lastActivity >= this.minInterval) {
            this.add(new SkipIntervalCls(this.lastActivity, time));
        }
        this.lastActivity = time;
    };
    ActivityManager.prototype.end = function () {
        if (this.endTime - this.lastActivity >= this.minInterval) {
            this.add(new SkipIntervalCls(this.lastActivity, this.endTime));
        }
    };
    return ActivityManager;
}(ListWalker));
export default ActivityManager;
