var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useCallback } from 'react';
import InlineInput from './InlineInput';
import stl from './inspector.css';
import cn from 'classnames';
var attrRE = /([A-Za-z][\w-]*)(?:=(?:"([^"]*)"|'([^']*)'|`([^`]*)`))?/g;
// Full trim: /^['"`\d\s\uFEFF\xA0]+|['"`\s\uFEFF\xA0]+$/g
function parseAttributes(s) {
    var attrs = {};
    var m;
    while (m = attrRE.exec(s)) {
        if (typeof m[1] !== 'string') {
            continue;
        }
        attrs[m[1]] = m[2] || m[3] || "";
    }
    return attrs;
}
export default function AttrView(_a) {
    var attr = _a.attr, forceUpdateElement = _a.forceUpdateElement;
    var _b = __read(useState(false), 2), editing = _b[0], setEditing = _b[1];
    var commit = useCallback(function (text) {
        var _a;
        setEditing(false);
        var attrs = parseAttributes(text);
        var curNamePresent = false;
        Object.entries(attrs).forEach(function (_a) {
            var _b;
            var _c = __read(_a, 2), name = _c[0], value = _c[1];
            curNamePresent = curNamePresent || name === attr.name;
            (_b = attr.ownerElement) === null || _b === void 0 ? void 0 : _b.setAttribute(name, value);
        });
        if (!curNamePresent) {
            (_a = attr.ownerElement) === null || _a === void 0 ? void 0 : _a.removeAttribute(attr.name);
        }
        // if (text.trim() === "") {
        //     attr.ownerElement.removeAttribute(attr.name);
        //     forceUpdateElement();
        //     return;
        // }
        // const parts = text.split("=");
        // if (parts.length != 2) { return; }
        // const trimRE = /^['"`\d\s\uFEFF\xA0]+|['"`\s\uFEFF\xA0]+$/g
        // const name = parts[0].replace(trimRE, '');
        // if (!/^[A-Za-z0-9:-]+$/.test(name)) { return; }
        // let value = parts[1];
        // if (value[0] === value[ value.length - 1 ] && "\'\"".includes(value[0])) {
        // 	value = value.slice(1, value.length - 1);
        // }
        // if (value.includes('"')) { return; } 
        // if (name !== attr.name) {
        // 	attr.ownerElement.removeAttribute(name);
        // }
        // attr.ownerElement.setAttribute(name, value);
        forceUpdateElement();
    }, []);
    return editing
        ? React.createElement(InlineInput, { value: attr.name + "=\"" + attr.value + "\"", commit: commit, className: cn("ml-2", stl.attributeInput) })
        : React.createElement("span", { className: "ml-2", onDoubleClick: function () { return setEditing(true); } },
            React.createElement("span", { className: stl.attributeName }, attr.name),
            attr.value &&
                React.createElement(React.Fragment, null,
                    '=',
                    '"',
                    React.createElement("span", { className: stl.attributeValue }, attr.value),
                    '"'));
}
