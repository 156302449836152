import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import stl from './roleForm.css';
import { save, edit } from 'Duck/roles';
import { Input, Button, Checkbox } from 'UI';
var RoleForm = function (_a) {
    var role = _a.role, closeModal = _a.closeModal, edit = _a.edit, save = _a.save, saving = _a.saving, permissions = _a.permissions;
    var focusElement = useRef(null);
    var _save = function () {
        save(role).then(function () {
            closeModal(role.exists() ? "Role updated" : "Role created");
        });
    };
    var write = function (_a) {
        var _b;
        var _c = _a.target, value = _c.value, name = _c.name;
        return edit((_b = {}, _b[name] = value, _b));
    };
    var onChangeOption = function (e) {
        var permissions = role.permissions;
        var index = permissions.indexOf(e);
        var _perms = permissions.contains(e) ? permissions.remove(index) : permissions.push(e);
        edit({ permissions: _perms });
    };
    useEffect(function () {
        focusElement && focusElement.current && focusElement.current.focus();
    }, []);
    return (React.createElement("div", { className: stl.form },
        React.createElement("form", { onSubmit: _save },
            React.createElement("div", { className: stl.formGroup },
                React.createElement("label", null, 'Name'),
                React.createElement(Input, { ref: focusElement, name: "name", value: role.name, onChange: write, className: stl.input, id: "name-field" })),
            React.createElement("div", null, permissions.map(function (permission, index) { return (React.createElement("div", { key: index, className: stl.formGroup },
                React.createElement(Checkbox, { name: "permissions", className: "font-medium", type: "checkbox", checked: role.permissions.contains(permission.value), onClick: function () { return onChangeOption(permission.value); }, label: permission.name }))); }))),
        React.createElement("div", { className: "flex items-center" },
            React.createElement("div", { className: "flex items-center mr-auto" },
                React.createElement(Button, { onClick: _save, disabled: !role.validate(), loading: saving, primary: true, marginRight: true }, role.exists() ? 'Update' : 'Add'),
                React.createElement(Button, { "data-hidden": !role.exists(), onClick: closeModal, outline: true }, 'Cancel')))));
};
export default connect(function (state) { return ({
    role: state.getIn(['roles', 'instance']),
    permissions: state.getIn(['roles', 'permissions']),
    saving: state.getIn(['roles', 'saveRequest', 'loading']),
}); }, { edit: edit, save: save })(RoleForm);
