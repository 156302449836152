var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import EventsBlock from '../Session_/EventsBlock';
import PageInsightsPanel from '../Session_/PageInsightsPanel/PageInsightsPanel';
import { Controls as PlayerControls } from 'Player';
import { Tabs } from 'UI';
import { connectPlayer } from 'Player';
import NewBadge from 'Shared/NewBadge';
var EVENTS = 'Events';
var HEATMAPS = 'Click Map';
var TABS = [EVENTS, HEATMAPS].map(function (tab) { return ({ text: tab, key: tab }); });
var EventsBlockConnected = connectPlayer(function (state) { return ({
    currentTimeEventIndex: state.eventListNow.length > 0 ? state.eventListNow.length - 1 : 0,
    playing: state.playing,
}); })(EventsBlock);
export default function RightBlock() {
    var _a = __read(useState(EVENTS), 2), activeTab = _a[0], setActiveTab = _a[1];
    var renderActiveTab = function (tab) {
        switch (tab) {
            case EVENTS:
                return React.createElement(EventsBlockConnected, { player: PlayerControls });
            case HEATMAPS:
                return React.createElement(PageInsightsPanel, null);
        }
    };
    return (React.createElement("div", { style: { width: '270px', height: 'calc(100vh- 50px)' }, className: "flex flex-col" },
        React.createElement("div", { className: "relative" },
            React.createElement(Tabs, { tabs: TABS, active: activeTab, onClick: function (tab) { return setActiveTab(tab); }, border: true }),
            React.createElement("div", { className: "absolute", style: { left: '160px', top: '13px' } }, React.createElement(NewBadge, null))),
        renderActiveTab(activeTab)));
}
