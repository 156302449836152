import React from 'react';
import { NoContent } from 'UI';
import { connectPlayer } from 'Player/store';
import SelectorCard from '../SelectorCard/SelectorCard';
import stl from './selectorList.css';
function SelectorsList(_a) {
    var targets = _a.targets, activeTargetIndex = _a.activeTargetIndex;
    return (React.createElement(NoContent, { title: "No data available.", size: "small", show: targets && targets.length === 0 },
        React.createElement("div", { className: stl.wrapper }, targets && targets.map(function (target, index) { return (React.createElement(SelectorCard, { target: target, index: index, showContent: activeTargetIndex === index })); }))));
}
export default connectPlayer(function (state) { return ({
    targets: state.markedTargets,
    activeTargetIndex: state.activeTargetIndex,
}); })(SelectorsList);
