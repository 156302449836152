var ListWalker = /** @class */ (function () {
    function ListWalker(list) {
        if (list === void 0) { list = []; }
        // Optimisation: #prop compiles to method that costs mor than strict property call.
        this._p = 0;
        this._list = list;
    }
    ListWalker.prototype.add = function (m) {
        return this.append(m);
    };
    ListWalker.prototype.append = function (m) {
        if (this.length > 0 && this.last && m.time < this.last.time) {
            console.error("Trying to append message with the less time then the list tail: ", m);
        }
        this._list.push(m);
    };
    ListWalker.prototype.reset = function () {
        this._p = 0;
    };
    ListWalker.prototype.sort = function (comparator) {
        // @ts-ignore
        this._list.sort(function (m1, m2) { return comparator(m1, m2) || (m1._index - m2._index); }); // indexes for sort stability (TODO: fix types???)
    };
    ListWalker.prototype.forEach = function (f) {
        this._list.forEach(f);
    };
    Object.defineProperty(ListWalker.prototype, "last", {
        // set pointer(p: number): void {
        // 	if (p >= this.length || p < 0) {
        // 		// console.error("Trying to set wrong pointer")
        // 		return;
        // 	}
        // 	this._p = p;
        // }
        get: function () {
            if (this._list.length === 0) {
                return null;
            }
            return this._list[this._list.length - 1];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ListWalker.prototype, "current", {
        get: function () {
            if (this._p === 0) {
                return null;
            }
            return this._list[this._p - 1];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ListWalker.prototype, "timeNow", {
        get: function () {
            if (this._p === 0) {
                return 0;
            }
            return this._list[this._p - 1].time;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ListWalker.prototype, "length", {
        get: function () {
            return this._list.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ListWalker.prototype, "maxTime", {
        get: function () {
            if (this.length === 0) {
                return 0;
            }
            return this._list[this.length - 1].time;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ListWalker.prototype, "minTime", {
        get: function () {
            if (this.length === 0) {
                return 0;
            }
            return this._list[0].time;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ListWalker.prototype, "listNow", {
        get: function () {
            return this._list.slice(0, this._p);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ListWalker.prototype, "list", {
        get: function () {
            return this._list;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ListWalker.prototype, "count", {
        get: function () {
            return this.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ListWalker.prototype, "countNow", {
        get: function () {
            return this._p;
        },
        enumerable: false,
        configurable: true
    });
    /*
        Returns last message with the time <= t.
        Assumed that the current message is already handled so
        if pointer doesn't cahnge <undefined> is returned.
    */
    ListWalker.prototype.moveToLast = function (t, index) {
        var key = "time"; //TODO
        var val = t;
        if (index) {
            key = "_index";
            val = index;
        }
        var changed = false;
        while (this._p < this.length && this._list[this._p][key] <= val) {
            this._p++;
            changed = true;
        }
        while (this._p > 0 && this._list[this._p - 1][key] > val) {
            this._p--;
            changed = true;
        }
        return changed ? this._list[this._p - 1] : null;
    };
    // moveToLastByIndex(i: number): ?T {
    // 	let changed = false;
    // 	while (!!this._list[this._p] && this._list[this._p]._index <= i) {
    // 		this._p++;
    // 		changed = true;
    // 	}
    // 	while (this._p > 0 && this._list[ this._p - 1 ]._index > i) {
    // 		this._p--;
    // 		changed = true;
    // 	}
    // 	return changed ? this._list[ this._p - 1 ] : undefined;
    // }
    ListWalker.prototype.moveApply = function (t, fn) {
        // Applying only in increment order for now
        if (t < this.timeNow) {
            this.reset();
        }
        while (!!this._list[this._p] && this._list[this._p].time <= t) {
            fn(this._list[this._p++]);
        }
        //while (this._p > 0 && this._list[ this._p - 1 ].time > t) {
        //	fnBack(this._list[ --this._p ]);
        //}
    };
    return ListWalker;
}());
export default ListWalker;
